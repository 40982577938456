import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { Router } from '@angular/router';
import { TaskService } from 'src/app/service/task.service';
import { UserService } from 'src/app/service/user.service';
import { Globals } from 'src/app/globals/globals';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private taskService: TaskService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private globals: Globals
  ) { }

  username: string;
  email: string;
  errorMessage: string;
  password: string;
  reenterPassword: string;
  phoneNum: any;
  usedRefCode: any;
  refCode: any;
  buttonLabel: any;

  fromApp: any;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.phoneNum = this.taskService.getPhoneNumber();
      this.usedRefCode = this.taskService.getExtras();
      this.refCode = !!(this.taskService.getExtras() == 'null') ? '' : this.taskService.getExtras();

      this.resetButton();
      if (window.location.href.includes('?')) {
        let queryString = window.location.href.split('?')[1];
        this.fromApp = queryString.includes('from-app=true');
        this.changeDetectorRef.detectChanges();
      }
    });
  }
  register() {
    if (this.refCode) {
      this.userService.getUserByRefCodeStatic(this.refCode).subscribe(data => {
        if (data.docs.length >= 1) {
          this.errorMessage = ""
          this.userService.signUp(this.email, this.password, this.username, this.phoneNum, this.refCode).then(res => {
            if (this.fromApp) {
            }
            else {
              let params: NavigationExtras = {
                queryParams: {
                  "from-register": true,
                }
              };
              this.router.navigate(['/download'], params);
            }
          }).catch(error => {
            this.resetButton();
            let details = {
              title: 'Sorry',
              desc: error.message
            }
            this.globals.presentDialog(details);
          });
        } else {
          this.errorMessage = "Invalid referral code"
          this.resetButton();
          return false;
        }
      })
    } else {
      this.refCode = null;
      this.userService.signUp(this.email, this.password, this.username, this.phoneNum, this.refCode).then(res => {
        if (this.fromApp) {
        }
        else {
          let params: NavigationExtras = {
            queryParams: {
              "from-register": true,
            }
          };
          this.router.navigate(['/download'], params);
        }
      }).catch(error => {
        this.resetButton();
        let details = {
          title: 'Sorry',
          desc: error.message
        }
        this.globals.presentDialog(details);
      });
    }
  }

  validateForm() {
    if (this.buttonLabel != 'Loading..') {
      this.buttonLabel = "Loading..";
      this.changeDetectorRef.detectChanges();
      if(this.username){
        this.userService.isUsernameInUseStatic(this.username).subscribe(res => {
          if (res.size > 0) {
            this.errorMessage = "Username already in use"
            this.resetButton();
            return false;
          }
          else if(!this.password || !this.username || !this.email || !this.reenterPassword){
            this.errorMessage = "Please make sure all fields are filled accurately"
            this.resetButton();
            return false;
          }
          else if ((this.username.length < 2 || this.username.length > 10)) {
            this.errorMessage = "Please enter a username that is between 2 to 10 characters in length"
            this.resetButton();
            return false;
          }
          else if ((this.password.length < 6)) {
            this.errorMessage = "Password cannot be less than 6 characters!"
            this.resetButton();
            return false;
          }
          else if(this.password != this.reenterPassword){
            this.errorMessage = "Please make sure you have re-entered your password accurately"
            this.resetButton();
            return false;
          }
          else if (!this.validateEmail(this.email)) {
            this.errorMessage = "Please enter a valid email!"
            this.resetButton();
            return false;
          }
          else {
            this.register();
          }
        })
      }
      else{
        this.errorMessage = "Please make sure all fields are filled accurately"
        this.resetButton();
        return false;
      }
    }
  }

  resetButton() {
    this.buttonLabel = "Submit";
    this.changeDetectorRef.detectChanges();
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

}
