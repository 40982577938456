import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { HomeComponent } from './component/home/home.component';
import { DownloadAppComponent } from './component/download-app/download-app.component';
import { FooterComponent } from './component/footer/footer.component';
import { AppDetailsComponent } from './component/app-details/app-details.component';
import { MerchantComponent } from './component/merchant/merchant.component';
import { BannerComponent } from './component/banner/banner.component';
import { DeliveryComponent } from './component/delivery/delivery.component';
import { FaqComponent } from './component/faq/faq.component';
import { HelpCenterComponent } from './component/help-center/help-center.component';
import { MerchantDetailsComponent } from './component/merchant-details/merchant-details.component';
import { MerchantFormComponent } from './component/merchant-form/merchant-form.component';
import { MerchantTncComponent } from './component/merchant-tnc/merchant-tnc.component';
import { DeliveryBannerComponent } from './component/delivery-banner/delivery-banner.component';
import { DeliveryDetailsComponent } from './component/delivery-details/delivery-details.component';
import { DeliveryTncComponent } from './component/delivery-tnc/delivery-tnc.component';
import { FaqDetailsComponent } from './component/faq-details/faq-details.component';
import { HelpFormComponent } from './component/help-form/help-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import {FirebaseUIModule} from 'firebaseui-angular';
import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import { AngularFirestore } from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import { RegisterFormComponent } from './component/register-form/register-form.component';
import { PhoneVerifComponent } from './component/phone-verif/phone-verif.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
    provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    recaptchaParameters: {
      type: 'image', // 'audio'
      size: 'invisible', // 'invisible' or 'compact'
      badge: 'bottomleft' //' bottomright' or 'inline' applies to invisible.
    },
    defaultCountry: 'SG', 
    whitelistedCountries: ['SG', '+65']
    }
  ],
  tosUrl: 'https://bytesg.com/assets/files/User%20and%20Deliveryman%20T&C.pdf',
  privacyPolicyUrl: 'https://bytesg.com/assets/files/Privacy%20Policy.pdf',
  credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM
};

import { AuthComponent } from './component/auth/auth.component';
import { AddCardComponent } from './component/add-card/add-card.component';
import { HttpClientModule } from '@angular/common/http';
import { ModalComponent } from './component/modal/modal.component';
import { CardModule } from 'ngx-card/ngx-card';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    DownloadAppComponent,
    FooterComponent,
    AppDetailsComponent,
    MerchantComponent,
    BannerComponent,
    DeliveryComponent,
    FaqComponent,
    HelpCenterComponent,
    MerchantDetailsComponent,
    MerchantFormComponent,
    MerchantTncComponent,
    DeliveryBannerComponent,
    DeliveryDetailsComponent,
    DeliveryTncComponent,
    FaqDetailsComponent,
    HelpFormComponent,
    RegisterFormComponent,
    PhoneVerifComponent,
    AuthComponent,
    AddCardComponent,
    ModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    AngularFireDatabaseModule,
    HttpClientModule,
    NgxSmartModalModule.forRoot(),
    CardModule,
  ],
  providers: [AngularFirestore, ModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
