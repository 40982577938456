import { Component, OnInit } from '@angular/core';
import { HelpMailService } from 'src/app/service/help-mail.service';
import { UserHelp } from 'src/app/model/user-help.model';
import { Globals } from 'src/app/globals/globals';

@Component({
  selector: 'app-help-form',
  templateUrl: './help-form.component.html',
  styleUrls: ['./help-form.component.scss']
})
export class HelpFormComponent implements OnInit {

  constructor(
    private helpMailService: HelpMailService,
    private globals: Globals
  ) { }

  ngOnInit() {
  }

  name: string;
  mobile: string;
  email: string;
  subject: string;
  message: string;
  errorMessage: string;
  valid: string;

  sendHelpForm() {
    let isFormValid = this.validateForm();
    if(isFormValid == true){
      const data: UserHelp = {
        bname: null,
        name: this.name,
        mobile: this.mobile,
        email: this.email,
        subject: this.subject,
        message: this.message
      }
      this.helpMailService.sendHelpForm(data).then(() =>{
        let details = {
          title: 'Success',
          desc: 'Sent successfully! We will get back to you immediately.'
        }
        this.globals.presentDialog(details);
        location.reload();
      }); 
    }
  }

  validateForm(){
    if((this.name == undefined || this.name.length < 1) || (this.mobile == undefined || this.mobile.length < 8) || this.subject == undefined || (this.message == undefined || this.message.length < 1) || (this.valid == undefined || this.valid.length < 1)){
      this.errorMessage = "Please make sure the form is filled up accurately!"
      return false;
    }
    else if(this.email == undefined || !this.validateEmail(this.email)){
      this.errorMessage = "Please enter a valid email!"
      return false;
    }
    else if(this.valid != 'I promise i am not a robot'){
      this.errorMessage = "Please type in 'I promise i am not a robot' under the validation field"
      return false;
    }
    else{
      return true;
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  
}
