import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'byte-web';
  showHeaderFooter: any;

  constructor(
  ) { }

  ngOnInit() {
      if(window.location.href.includes('from-app=true')){
        this.showHeaderFooter = false;
      }
      else{
        this.showHeaderFooter = true;
      }
  }

}
