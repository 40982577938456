import { Injectable } from '@angular/core';
import { ModalComponent } from '../component/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class Globals {

  constructor(
    private modalComponent: ModalComponent
  ) {
  }

  presentDialog(data){
    this.modalComponent.openModal(data);
  }

  getFullDate(str){
    if (str.length == 1) {
      return '0' + str;
    }
    return str;
  }

  getMonthName(monthIndex) {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[monthIndex];
  }

  getFormattedDateAndTime() {//mmm dd, yyyy hh:mm aa
    return this.getMonthName(new Date(Date.now()).getUTCMonth()) + ' ' + new Date(Date.now()).getDate() + ', ' + new Date(Date.now()).getFullYear() + ' ' + this.formatAMPM(new Date(Date.now()));
  }

  formatAMPM(date) {//hh:mm aa
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
}
