import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  constructor(
    private db: AngularFirestore,
    ) { }

  setPromo(id, promo){
    return this.db.collection('promos-rewards-v2').doc(id).set(promo);
  }

}
