import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MerchantComponent } from './component/merchant/merchant.component';
import { HomeComponent } from './component/home/home.component';
import { DeliveryComponent } from './component/delivery/delivery.component';
import { FaqComponent } from './component/faq/faq.component';
import { HelpCenterComponent } from './component/help-center/help-center.component';
import { PhoneVerifComponent } from './component/phone-verif/phone-verif.component';
import { AuthComponent } from './component/auth/auth.component';
import { AddCardComponent } from './component/add-card/add-card.component';
import { RegisterFormComponent } from './component/register-form/register-form.component';
import { DownloadAppComponent } from './component/download-app/download-app.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'merchant', component: MerchantComponent },
  { path: 'delivery', component: DeliveryComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'help-center', component: HelpCenterComponent },
  { path: 'register/:id', component: PhoneVerifComponent },
  { path: 'registerform', component: RegisterFormComponent },
  { path: 'download', component: DownloadAppComponent },
  { path: 'auth', component: AuthComponent },
  { path: 'add-card/:uid', component: AddCardComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {


 }
