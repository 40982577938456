import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from 'src/app/service/payment.service';
import { UserService } from 'src/app/service/user.service';
import { Subscription } from 'rxjs';
import { Globals } from 'src/app/globals/globals';
// import { environment } from 'src/environments/environment'
// declare var Stripe;
// var stripe = Stripe(environment.card.key);
// var elements = stripe.elements();

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {

  uid: any;
  isLoading: any;
  valid: any;
  subscription: Subscription;
  card: any;
  isButtonDisabled: any;
  buttonText: any;

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private userService: UserService,
    private globals: Globals
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.buttonText = "Add Card";
    this.route.params.subscribe(params => {
      this.uid = params['uid'];
      if (!!this.uid) {
        this.valid = true;
        this.isLoading = false;
      }
    });

    this.paymentService.getMessage().subscribe(message => {
      this.buttonText = "Add Card";
      this.isButtonDisabled = false;
    });
  }

  // ngAfterViewInit() {
  //   let options = {
  //     hidePostalCode: true,
  //     style: {
  //       base: {
  //         lineHeight: '40px',
  //         fontWeight: 400,
  //         fontFamily: '"Poppins", sans-serif',
  //         fontSize: '16px',
  //       },
  //     }
  //   };

  //   this.card = elements.create('card', options);
  //   this.card.mount('#card-element');
  //   this.card.addEventListener('change', (event) => {
  //     let displayError = document.getElementById('card-errors');
  //     if (event.error) {
  //       displayError.textContent = event.error.message;
  //       this.isButtonDisabled = true;
  //     } else {
  //       displayError.textContent = '';
  //       this.isButtonDisabled = false;
  //     }
  //   });
  //   let form = document.getElementById('payment-form');
  //   let hiddenInput = document.createElement('input');
  //   hiddenInput.setAttribute('type', 'hidden');
  //   hiddenInput.setAttribute('name', 'stripeToken');
  //   hiddenInput.setAttribute('value', 'SG');
  //   form.appendChild(hiddenInput);
  // }

  // addCard() {
  //   if (this.isButtonDisabled) {
  //     let displayError = document.getElementById('card-errors');
  //     let details = {
  //       title: 'Sorry',
  //       desc: displayError.textContent
  //     }
  //     this.globals.presentDialog(details);
  //   }
  //   else {
  //     this.buttonText = "Loading...";
  //     this.isButtonDisabled = true;
  //     stripe.createToken(this.card).then((result) => {
  //       if (result.error) {
  //         this.buttonText = "Add Card";
  //         this.isButtonDisabled = false;
  //         let errorElement = document.getElementById('card-errors');
  //         errorElement.textContent = result.error.message;
  //         let details = {
  //           title: 'Sorry',
  //           desc: result.error.message
  //         }
  //         this.globals.presentDialog(details);
  //       } else {
  //         this.userService.getUserByIdStatic(this.uid).subscribe(data => {
  //           if(data.exists){
  //             this.paymentService.validateCard(result.token.id, this.uid, data.data()['cardCustomerId']);
  //           }
  //           else{
  //             this.buttonText = "Add Card";
  //             this.isButtonDisabled = false;
  //             let details = {
  //               title: 'Sorry',
  //               desc: 'Please make sure you are logged in via Byte app'
  //             }
  //             this.globals.presentDialog(details);
  //           }
  //         })
  //       }
  //     });
  //   }
  // }

  addCard() {
    if (!this.isButtonDisabled) {
      this.isButtonDisabled = true;
      this.buttonText = "Loading...";
      let expiryVal = ((<HTMLInputElement>document.getElementsByName("expiry")[0])).value;
      if (!!expiryVal.match(/^(0[1-9]|1[0-2]) \/ [0-9]{4}$/) || !!expiryVal.match(/^(0[1-9]|1[0-2])\/[0-9]{4}$/)) {

        if (!!expiryVal.match(/^(0[1-9]|1[0-2])\/[0-9]{4}$/)) {
          expiryVal = expiryVal.split('/')[0] + ' / ' + expiryVal.split('/')[1];
        }

        let paymentDetails = {
          cardNumber: (<HTMLInputElement>document.getElementsByName("number")[0]).value.replace(/\s/g, ''),
          fullName: (<HTMLInputElement>document.getElementsByName("first-name")[0]).value + ' ' + (<HTMLInputElement>document.getElementsByName("last-name")[0]).value,
          expiryMonth: expiryVal.split(' / ')[0],
          expiryYear: expiryVal.split(' / ')[1],
          cvv: (<HTMLInputElement>document.getElementsByName("cvc")[0]).value
        }

        this.userService.getUserByIdStatic(this.uid).subscribe(data => {
          if (data.exists) {
            this.paymentService.validateCard(paymentDetails, this.uid, data.data()['cardCustomerId']);
          }
          else {
            this.isButtonDisabled = false;
            this.buttonText = "Add Card";
            let details = {
              title: 'Sorry',
              desc: 'Please make sure you are logged in via Byte app'
            }
            this.globals.presentDialog(details);
          }
        })
      }
      else {
        this.isButtonDisabled = false;
        this.buttonText = "Add Card";
        let details = {
          title: 'Sorry',
          desc: 'Please make sure you have entered your card expiry correctly (e.g.  mm / yyyy)'
        }
        this.globals.presentDialog(details);
      }
    }
  }


}
