import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {

  fromRegister: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if(window.location.href.includes('?')){
      let queryString = window.location.href.split('?')[1];
      this.fromRegister = queryString.includes('from-register=true');
      this.changeDetectorRef.detectChanges();
    }
  }

}
