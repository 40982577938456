import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  extras: any;
  phoneNumber: any;

  constructor() { }

  setPhoneNumber(data){
    this.phoneNumber = data;
  }

  getPhoneNumber(){
    return this.phoneNumber;
  }

  setExtras(data){
    this.extras = data;
  }

  getExtras(){
    return this.extras;
  }

}
