import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Globals } from '../globals/globals';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { PromoService } from './promoservice.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  credential: any;

  constructor(
    private firestore: AngularFirestore,
    private globals: Globals,
    private afAuth: AngularFireAuth,
    public afd: AngularFireDatabase,
    public db: AngularFirestore,
    private promoService: PromoService
  ) { }

  signUp(email, password, username, phoneNum, refCode) {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(newUser => {
        this.loginUser(email, password);
        return firebase.auth().onAuthStateChanged(user => {
          if (user) {
            let today = this.globals.getFullDate(new Date(Date.now()).getDate().toString()) + '/' + this.globals.getFullDate((new Date(Date.now()).getMonth() + 1).toString()) + '/' + new Date(Date.now()).getFullYear().toString();
            let twoWeeksLaterUnformatted = new Date(Date.now());
            twoWeeksLaterUnformatted.setDate(twoWeeksLaterUnformatted.getDate() + 14);
            let twoWeeksLater = this.globals.getFullDate(new Date(twoWeeksLaterUnformatted).getDate().toString()) + '/' + this.globals.getFullDate((new Date(twoWeeksLaterUnformatted).getMonth() + 1).toString()) + '/' + twoWeeksLaterUnformatted.getFullYear().toString();

            let promoArr = [];
            let promoToSet = null;

            if (!!refCode) {
              let refCodeObj = {
                promoId: user.uid + '-' + refCode,
                promoCode: refCode,
                startDate: today,
                endDate: twoWeeksLater,
                amountOff: '$2',
                cappedAt: "",
                description: "",
                promoType: "specialPromo",
                minSpend: 8,
                assignedTo: user.uid,
                isPartnerPromo: 'F',
                partnerPromoId: ''
              }
              promoArr.push(refCodeObj.promoCode);
              promoToSet = refCodeObj;

              return this.promoService.setPromo(promoToSet.promoId, promoToSet).then(()=>{
                return this.db.collection('userDetails-v2').doc(user.uid).set((user.uid, {
                  uid: user.uid,
                  username: username,
                  timestampCreated: this.globals.getFormattedDateAndTime(),
                  phoneNumber: phoneNum,
                  refCode: username + '-ref',
                  email: email,
                  profilePhoto: null,
                  usedPromoCode: [],
                  points: 0,
                  availPromoCodes: promoArr,
                  eWalletEarned: 0,
                  eWalletTopUp: 0,
                  tier: 'Noob',
                  userType: "user",
                  cardCustomerId: '',
                  cardRecipientId: ''
                })).then(()=>{
                  window.parent.window.postMessage({"email":email,"password":password}, '*');
                });
              })
            }
            else{
              return this.db.collection('userDetails-v2').doc(user.uid).set((user.uid, {
                uid: user.uid,
                username: username,
                timestampCreated: this.globals.getFormattedDateAndTime(),
                phoneNumber: phoneNum,
                refCode: username + '-ref',
                email: email,
                profilePhoto: null,
                usedPromoCode: [],
                points: 0,
                availPromoCodes: promoArr,
                eWalletEarned: 0,
                eWalletTopUp: 0,
                tier: 'Noob',
                userType: "user",
                cardCustomerId: '',
                cardRecipientId: ''
              })).then(()=>{
                window.parent.window.postMessage({"email":email,"password":password}, '*');
              });
            }
          }
        })
      })
  }

  loginUser(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  logoutUser() {
    return this.afAuth.auth.signOut();
  }

  updateUserNumber(id, phoneNumber){
    window.parent.window.postMessage({"uid":id, "phoneNumber":phoneNumber}, '*');
  }

  getUserById(id){
    return this.firestore.collection('userDetails-v2').doc(id).snapshotChanges();
  }

  getUserByIdStatic(id){
    return this.firestore.collection('userDetails-v2').doc(id).get();
  }

  updateUserCardCustomerId(id, cardCustomerId){
    return this.firestore.collection('userDetails-v2').doc(id).update({
      cardCustomerId: cardCustomerId
    });
  }

  resetEmailForUser(email, fromEmail) {
    let user = this.firestore.collection('userDetails-v2', ref => ref.where('email', '==', fromEmail)).get();
    user.subscribe(data => {
      let uid = data.docs[0].id;
      this.firestore.collection('userDetails-v2').doc(uid).update({
        email: email
      }).then(() => {
        let details = {
          title: 'Success',
          desc: 'Success! You have successfully reverted your email. If you would like to reset your password, please use Byte app and select on Forgot Password.'
        }
        this.globals.presentDialog(details);
        location.reload();
      }).catch((error) => {
        let details = {
          title: 'Sorry',
          desc: 'Sorry, an error occured. Please try again or use Byte app to request for a new link. If the issue persist, please contact us.'
        }
        this.globals.presentDialog(details);
        location.reload();
      });
    })
  }

  getUserByPhoneNumberStatic(phoneNum){
    return this.firestore.collection('userDetails-v2', ref => ref.where('phoneNumber', '==', phoneNum)).get();
  }

  getUserByRefCodeStatic(refCode){
    return this.firestore.collection('userDetails-v2', ref => ref.where('refCode', '==', refCode)).get();
  }

  isUsernameInUseStatic(username){
    return this.firestore.collection('userDetails-v2', ref => ref.where('username', '==', username)).get();
  }

}
