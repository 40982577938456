import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  dismiss: any;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
  ) { }

  ngOnInit() {
    this.dismiss = "Press anywhere outside to dismiss"
  }

  openModal(data){
    const obj: Object = {
      title: data.title,
      desc: data.desc
    };

    this.ngxSmartModalService.setModalData(obj, 'myModal');
    this.ngxSmartModalService.getModal('myModal').open();
  }

}
