import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as firebase from 'firebase';
import { UserService } from 'src/app/service/user.service';
import { Globals } from 'src/app/globals/globals';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  oobCode: any;
  mode: any;
  isValid: any;
  errorMessage: any;
  isLoading: any;

  value: string;

  constructor(
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private globals: Globals
  ) {
   }

  ngOnInit() {
    this.isValid = false;
    this.errorMessage = "Page is loading..."
    this.changeDetectorRef.detectChanges();
    if(window.location.href.includes('?')){
      let queryString = window.location.href.split('?')[1];
      let paramsArr = queryString.split('&');
      this.mode = paramsArr[0].split('=')[1];
      this.oobCode = paramsArr[1].split('=')[1];
      this.changeDetectorRef.detectChanges();
    }
    if(this.mode =='resetPassword'){
      firebase.auth().verifyPasswordResetCode(this.oobCode)
      .then(()=> {
        this.isValid = true;
        this.changeDetectorRef.detectChanges();
      })
      .catch(()=> {
        this.errorMessage = "Either you have succesfully reset your password already or your link has expired. If your link has expired, please use Byte app to request for a new link. If the issue persist, please contact us."
        this.changeDetectorRef.detectChanges();
        let details = {
          title: 'Sorry',
          desc: 'Either you have succesfully reset your password already or your link has expired. If your link has expired, please use Byte app to request for a new link. If the issue persist, please contact us.'
        }
        this.globals.presentDialog(details);
      })
    }
    else if(this.mode == 'recoverEmail'){
      firebase.auth().checkActionCode(this.oobCode).then(()=> {
        this.isValid = true;
        this.changeDetectorRef.detectChanges();
      }).catch((error)=> {
        this.errorMessage = "Either you have successfully reverted your email or your link has expired. If your link has expired, please use Byte app to request for a new link. If the issue persist, please contact us."
        this.changeDetectorRef.detectChanges();
        let details = {
          title: 'Sorry',
          desc: 'Either you have successfully reverted your email or your link has expired. If your link has expired, please use Byte app to request for a new link. If the issue persist, please contact us.'
        }
        this.globals.presentDialog(details);
      });
    }
    else{
      this.errorMessage = "Sorry, you dont have the rights to view this page..."
      this.changeDetectorRef.detectChanges();
    }
  }

  submitAcc(){
    if(this.mode =='resetPassword'){
      if(this.value.length>5){
        firebase.auth().confirmPasswordReset(this.oobCode, this.value)
        .then(() =>{
          let details = {
            title: 'Success',
            desc: 'Success! You have successfully updated your password'
          }
          this.globals.presentDialog(details);
          location.reload();
        })
        .catch(()=> {
          let details = {
            title: 'Sorry',
            desc: 'Sorry, we are unable to process your request. Please try again. If the issue persist, please contact us.'
          }
          this.globals.presentDialog(details);
        })
      }
      else{
        let details = {
          title: 'Sorry',
          desc: 'Sorry, please make sure your password is at least 6 characters long'
        }
        this.globals.presentDialog(details);
      }
    }
    else if(this.mode == 'recoverEmail'){
      this.isLoading = true;
      firebase.auth().checkActionCode(this.oobCode).then((info)=> {
        let restoredEmail = info['data']['email'];
        let fromEmail = info['data']['fromEmail'];
        firebase.auth().applyActionCode(this.oobCode).then(()=>{
          this.userService.resetEmailForUser(restoredEmail, fromEmail);
        }).catch((error)=> {
          let details = {
            title: 'Sorry',
            desc: 'Sorry, an error occured. Please try again or use Byte app to request for a new link. If the issue persist, please contact us.'
          }
          this.globals.presentDialog(details);
          location.reload();
        });
      })
    }
  }

}
