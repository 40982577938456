import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class HelpMailService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  sendHelpForm(value) {
    return this.firestore.collection('user-help-v2').add({
      bname: null,
      name: value.name,
      mobile: value.mobile,
      email: value.email,
      subject: value.subject,
      message: value.message
    });
  }

  sendMerchantHelpForm(value){
    return this.firestore.collection('user-help-v2').add({
      bname: value.bname,
      name: value.name,
      mobile: value.mobile,
      email: value.email,
      subject: value.subject,
      message: value.message
    });
  }
}
