import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as firebase from 'firebase/app';
import { FirebaseUISignInSuccessWithAuthResult, FirebaseuiAngularLibraryService } from 'firebaseui-angular';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { TaskService } from 'src/app/service/task.service';
import { UserService } from 'src/app/service/user.service';
import { Globals } from 'src/app/globals/globals';

@Component({
  selector: 'app-phone-verif',
  templateUrl: './phone-verif.component.html',
  styleUrls: ['./phone-verif.component.scss']
})
export class PhoneVerifComponent implements OnInit {
  sub;
  id;
  phoneNumber;

  isLoading: any;
  fromApp: any;
  forUpdateNumber: any;
  
  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private taskService: TaskService,
    private firebaseuiAngularLibraryService: FirebaseuiAngularLibraryService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private globals: Globals
    ) { 
      this.userService.logoutUser();
      firebaseuiAngularLibraryService.firebaseUiInstance.disableAutoSignIn();
  }

  ngOnInit() {
    this.isLoading = false;
    this.changeDetectorRef.detectChanges();
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      if(window.location.href.includes('?')){
        let queryString = window.location.href.split('?')[1];
        this.fromApp = queryString.includes('from-app=true');
        this.forUpdateNumber = queryString.includes('update-number=true');
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    this.isLoading = true;
    this.changeDetectorRef.detectChanges();
    this.userService.getUserByPhoneNumberStatic(firebase.auth().currentUser.phoneNumber).subscribe(data=>{
      if(data.docs.length >= 1) {
        firebase.auth().currentUser.delete().then(() =>{
          let details = {
            title: 'Sorry',
            desc: 'Phone number has been registered before, please try with a different number'
          }
          this.globals.presentDialog(details);
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
          this.userService.logoutUser();
        }).catch((error) =>{
          let details = {
            title: 'Sorry',
            desc: 'Sorry, an error occured. Please try again."'
          }
          this.globals.presentDialog(details);
        });
      } else {
        this.taskService.setPhoneNumber(firebase.auth().currentUser.phoneNumber);
        this.taskService.setExtras(this.id);
        firebase.auth().currentUser.delete().then(()=> {
          if(this.forUpdateNumber){
            this.userService.updateUserNumber(this.id, this.taskService.getPhoneNumber());
          }
          else{
            if(this.fromApp){
              let params: NavigationExtras = {
                queryParams: {
                  "from-app": true,
                }
              };
              this.router.navigate(['/registerform'], params);
            }
            else{
              this.router.navigateByUrl('/registerform');
            }
          }
        }).catch((error) =>{
          let details = {
            title: 'Sorry',
            desc: 'Sorry, an error occured. Please try again."'
          }
          this.globals.presentDialog(details);
        });
      }
      })
  }

}
