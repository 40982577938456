import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { environment } from '../../environments/environment'
import { Globals } from '../globals/globals';
declare var Omise;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private subject = new Subject<any>();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private globals: Globals
  ) { }

  sendMessage(message) {
    this.subject.next({ text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  // validateCard(data, uid, cardCustomerId) {
  //   if (cardCustomerId) {
  //     this.http.get(environment.server.url + '/update-customer-with-card/' + cardCustomerId + '/' + data).subscribe(data => {
  //       if (data["err"]) {
  //         let details = {
  //           title: 'Sorry',
  //           desc: data['err'].message
  //         }
  //         this.globals.presentDialog(details);
  //         this.sendMessage(false);
  //       }
  //       else {
  //         window.parent.window.postMessage({ "card": true }, '*');
  //       }
  //     })
  //   }
  //   else {
  //     this.http.get(environment.server.url + '/create-customer-with-card/' + data).subscribe(data => {
  //       if (data["err"]) {
  //         let details = {
  //           title: 'Sorry',
  //           desc: data['err'].message
  //         }
  //         this.globals.presentDialog(details);
  //         this.sendMessage(false);
  //       }
  //       else {
  //         this.userService.updateUserCardCustomerId(uid, data['customer'].id).then(res => {
  //           window.parent.window.postMessage({"card":true}, '*');
  //         })
  //       }
  //     })
  //   }
  // }

  validateCard(data, uid, cardCustomerId) {
    let card = {
      name: data.fullName,
      number: data.cardNumber,
      expiration_month: data.expiryMonth,
      expiration_year: data.expiryYear,
      security_code: data.cvv,
      city: 'Singapore',
    }

    Omise.setPublicKey(environment.card.key);

    Omise.createToken('card', card, (statusCode, response) => {
      if (statusCode == 200) {
        this.http.get(environment.server.url + '/validate-card/' + response.id).subscribe(data => {
          if (data["err"]) {
            let details = {
              title: 'Sorry',
              desc: "Please make sure you have entered a valid card. If you think this is an issue, please contact us at contactus@bytesg.com"
            }
            this.globals.presentDialog(details);
            this.sendMessage(false);
          }
          else {
            Omise.createToken('card', card, (statusCode, response) => {
              if (statusCode == 200) {
                if (cardCustomerId) {
                  this.http.get(environment.server.url + '/update-customer-with-card/' + cardCustomerId + '/' + response.id).subscribe(data => {
                    if (data["err"]) {
                      let details = {
                        title: 'Sorry',
                        desc: data['err'].message
                      }
                      this.globals.presentDialog(details);
                      this.sendMessage(false);
                    }
                    else {
                      window.parent.window.postMessage({ "card": true }, '*');
                    }
                  })
                }
                else {
                  this.http.get(environment.server.url + '/create-customer-with-card/' + response.id).subscribe(data => {
                    if (data["err"]) {
                      let details = {
                        title: 'Sorry',
                        desc: data['err'].message
                      }
                      this.globals.presentDialog(details);
                      this.sendMessage(false);
                    }
                    else {
                      this.userService.updateUserCardCustomerId(uid, data['customer'].id).then(res => {
                        window.parent.window.postMessage({ "card": true }, '*');
                      })
                    }
                  })
                }
              }
              else {
                let details = {
                  title: 'Sorry',
                  desc: response.message
                }
                this.globals.presentDialog(details);
                this.sendMessage(false);
              }
            })
          }
        })
      } else {
        let details = {
          title: 'Sorry',
          desc: response.message
        }
        this.globals.presentDialog(details);
        this.sendMessage(false);
      }
    })
  }
}
