import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-details',
  templateUrl: './faq-details.component.html',
  styleUrls: ['./faq-details.component.scss']
})
export class FaqDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  toggleAnswer(event) {
    if (!event.srcElement.localName.includes('svg')) {
      if (event.srcElement.className.includes('question')) {
        if (event.srcElement.nextElementSibling.className == 'answer dis-block') {
          event.srcElement.nextElementSibling.className = 'answer dis-none';
        }
        else {
          event.srcElement.nextElementSibling.className = 'answer dis-block';
        }
      }
      else if (event.srcElement.className.includes('answer')) {
        if (event.srcElement.className == 'answer dis-block') {
          event.srcElement.className = 'answer dis-none';
        }
        else {
          event.srcElement.className = 'answer dis-block';
        }
      }
      else if (event.srcElement.className.includes('item')) {
        if (event.srcElement.lastElementChild.className == 'answer dis-block') {
          event.srcElement.lastElementChild.className = 'answer dis-none';
        }
        else {
          event.srcElement.lastElementChild.className = 'answer dis-block';
        }
      }
    }
    else {
      if (event.srcElement.parentElement.nextElementSibling.className == 'answer dis-block') {
        event.srcElement.parentElement.nextElementSibling.className = 'answer dis-none';
      }
      else {
        event.srcElement.parentElement.nextElementSibling.className = 'answer dis-block';
      }
    }
  }
}
