//prod
export const environment = {
  production: false,
  server: {
    url: "https://server.bytesg.com"
  },
  card: {
    // key: "pk_live_1aLAjeP2bzJvt7D7G1oVFU3b001OOVVzdu",
    key: "pkey_5h3ymswq53378vf02w6",
  },
  firebaseConfig : {
    apiKey: "AIzaSyDd-9YPEzEFDLiU_I1InOYlPZodhzQ97TM",
    authDomain: "byte-f0464.firebaseapp.com",
    databaseURL: "https://byte-f0464.firebaseio.com",
    projectId: "byte-f0464",
    storageBucket: "byte-f0464.appspot.com",
    messagingSenderId: "492517534745",
    appId: "1:492517534745:web:a59bb396f1c72675"
  }
};
//dev
// export const environment = {
//   production: false,
//   server: {
//     url: "http://localhost:8080"
//   },
//   card: {
//     // key: "pk_test_keojD5NBzvKbY995Decz0bH900ntP0S0on",
//     key: "pkey_test_5go4cz40mm155r6lvap",
//   },
//   firebaseConfig: {
//     apiKey: "AIzaSyAL5o1BkxUWr9kpkRjUqEr5AIsfcuP6i1Y",
//     authDomain: "byte-test-c2b83.firebaseapp.com",
//     databaseURL: "https://byte-test-c2b83.firebaseio.com",
//     projectId: "byte-test-c2b83",
//     storageBucket: "byte-test-c2b83.appspot.com",
//     messagingSenderId: "404544685803",
//     appId: "1:404544685803:web:0c340e9eca5e851d"
//   }
// };